import React from "react";
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    BooleanInput,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    Create,
    SimpleList,
    SelectField,
} from 'react-admin';
import { useMediaQuery} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {statusChoices} from "./config";
import RichTextInput from "./fields/richText";

export const EmailTemplateList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.id}
                    secondaryText={record => record.subject}
                    tertiaryText={record => record.active ? 'attivo' : 'non attivo'}
                />
            ) : (
                <Datagrid>
                    <SelectField
                        fullWidth
                        source="id"
                        choices={statusChoices}
                        sortable={false}
                    />
                    <BooleanField source="active" sortable={false} />
                    <TextField source="subject" sortable={false} />
                    <EditButton />
                </Datagrid>
            )}
        </List>
    );
}

export const EmailTemplateEdit = withStyles({ card: { overflow: 'initial' } })(props => (
    <Edit {...props}>
        <SimpleForm onSubmit={console.log}>
            <SelectInput
                fullWidth
                source="id"
                choices={statusChoices}
            />
            <BooleanInput fullWidth label="Attivo" source="active" />
            <TextInput fullWidth source="subject" />
            <RichTextInput source="template" />
        </SimpleForm>
    </Edit>
));

export const EmailTemplateCreate = props => (
    <Create {...props}>
        <SimpleForm onSubmit={console.log}>
            <SelectInput
                fullWidth
                source="id"
                choices={statusChoices}
            />
            <BooleanInput fullWidth label="Attivo" source="active" />
            <TextInput fullWidth source="subject" />
            <RichTextInput source="template" />
        </SimpleForm>
    </Create>
);
