import React, {useEffect} from "react";

const ExternalPage = () => {
    useEffect(() => {
        window.initShop()
    }, []);

    return (
        <div id="my-store-40565273" />
    )
}

export default ExternalPage