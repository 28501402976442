import React, {useState} from "react";
import {FormControlLabel, Switch} from "@material-ui/core";
import {TextInput} from "react-admin";
import RaRichTextInput from "ra-input-rich-text";


const RichTextInput = ({source}) => {
    const [codeView, setCodeView] = useState(false)
    return (
        <>
            <FormControlLabel
                control={
                    <Switch
                        checked={codeView}
                        onChange={(event, checked) => setCodeView(checked)}
                        name="codeViewSwitcher"
                        color="primary"
                    />
                }
                label={codeView ? 'passa a Visual Editor' : 'passa a Codice HTML'}
            />
            {codeView ? (
                <TextInput fullWidth source={source} multiline minRows={25}/>
            ) : (
                <RaRichTextInput
                    fullWidth
                    source={source}
                />
            )}
        </>
    )
}

export default RichTextInput
